import React from "react"
import s from './Paginator.module.scss'
import { useDispatch, useSelector } from "react-redux"
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons"
import { fetchCollection } from "../../store/collectionSlice"


export const Paginator = ({ url }) => {
  const dispatch = useDispatch()
  const { count, currentPage } = useSelector((state) => state.collection)

  const totalPages = Math.ceil(count / 10)
  const startPage = Math.max(1, Math.min(currentPage - 2, totalPages - 4))
  const endPage = Math.min(totalPages, startPage + 4)

  const pageNumbers = Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
  )

  const goToPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
          dispatch(fetchCollection(`${url}${pageNumber}`))
      }
  }

  return (
      <div className={s.paginator}>
          {currentPage > 1 && (
              <ArrowBackIos
                  className={s.arrow_paginator}
                  onClick={() => goToPage(1)}
              />
          )}

          {pageNumbers.map((pageNumber) => (
              <span
                  onClick={() => goToPage(pageNumber)}
                  className={
                      currentPage === pageNumber
                          ? s.page_current
                          : s.page_number
                  }
                  key={pageNumber}
              >
                  {pageNumber}
              </span>
          ))}

          {currentPage < totalPages && (
              <ArrowForwardIos
                  className={s.arrow_paginator}
                  onClick={() => goToPage(totalPages)}
              />
          )}
      </div>
  )
}