import { useTranslation } from "react-i18next"
import s from './CheckboxContainer.module.scss'


export const CheckboxContainer = ({containerRef, setVisibleColumns, visibleColumns}) => {
    const { t } = useTranslation()

    const handleContainerClick = (event) => {
        event.stopPropagation()
    }

    const handleCheckboxChange = (column) => {
        setVisibleColumns((prevState) => ({
            ...prevState,
            [column]: !prevState[column],
        }))
    }
    return (
        <div ref={containerRef} onClick={handleContainerClick} className={s.checkboxContainer}>
            <label>
                <input
                    type="checkbox"
                    checked={visibleColumns.date}
                    onChange={() => handleCheckboxChange('date')}
                />
                {t('Date')}
            </label>
            <label>
                <input
                    type="checkbox"
                    checked={visibleColumns.GPS}
                    onChange={() => handleCheckboxChange('GPS')}
                />
                {t('GPS')}
            </label>
            <label>
                <input
                    type="checkbox"
                    checked={visibleColumns.temperature}
                    onChange={() => handleCheckboxChange('temperature')}
                />
                {t('Temperature')}
            </label>
            <label>
                <input
                    type="checkbox"
                    checked={visibleColumns.trapName}
                    onChange={() => handleCheckboxChange('trapName')}
                />
                {t('TrapName')}
            </label>
        </div>
    )
}