import { useEffect } from "react"
import { ReactComponent as UploadBird } from '../../assets/uploadBird.svg'
import s from './ProgressBar.module.scss'

export const ProgressBar = ({progress, isUpload, t}) => {
    useEffect(() => {
        document.body.classList.add("no-scroll")
        return () => {
            document.body.classList.remove("no-scroll")
        }
    }, [])
    return (
        <div className={s.progress_container}>
            <div className={s.progress_box}>
                <div className={s.picture}><UploadBird /></div>
                <div className={s.progress}>{progress}%</div>
                <div className={s.text}>
                    {isUpload
                        ? t('uploadingProgress')
                        : t('recognitionProgress')
                    }
                    </div>
            </div>
        </div>
    )

}