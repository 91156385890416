import React, { useEffect, useRef, useState } from "react";
import s from '../TablePage.module.scss'
import { useDispatch, useSelector } from "react-redux";
import { fetchCollection } from "../../../store/collectionSlice";
import { Paginator } from "../../Paginator/Paginator";
import { Loader } from "../../Loader/Loader";
import { useTranslation } from "react-i18next";
import { RawOfTableSoundTrap } from "./RawOfTable/RawOfTablesoundTrap"
import { BoxInfo } from "./BoxInfo/BoxInfo"
import { getCsvLink, recognizeSoundTrapsFiles, startPollingProgress, uploadSoundTrapsFiles } from "../../../store/soundTrapsSlice";
import { DateRangeWithTimePicker } from "../../DateRangePicker/DateRangePicker";
import { ProgressBar } from "../../ProgressBar/ProgressBar";
import { CheckboxContainer } from "../../CheckboxContainer/CheckboxContainer";

export const SoundTraps = () => {
    const dispatch = useDispatch()
    const { collection, status, error } = useSelector(state => state.collection)
    const { link, recognitionProgress } = useSelector(state => state.soundTraps)
    const { t } = useTranslation()
    const containerRef = useRef(null)
    const datePickerRef = useRef(null)
    const [isVisible, setIsVisible] = useState(false)
    const [progress, setProgress] = useState(null)
    const [isVisibleDatePicker, setIsVisibleDatePicker] = useState(false)
    const [visibleColumns, setVisibleColumns] = useState({
        date: true,
        GPS: true,
        temperature: true,
        trapName: true,
    })
    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
    ])

    const [startTime, setStartTime] = useState('00:00') 
    const [endTime, setEndTime] = useState('23:59') 
    const [paginatorUrl, setPaginatorUrl] = useState('https://ml1.bird-trap-db.ssrlab.by/api/birds?page_size=10&page_num=') 
    const formatDate = (date)=> {
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        return `${year}-${month}-${day}`
    }

    const toggleContainer = (event) => {
        event.stopPropagation()
        setIsVisible((prev) => !prev)
    }
    const toggleDatePicker = (event) => {
        event.stopPropagation()
        setIsVisibleDatePicker((prev) => !prev)
    }
    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setIsVisible(false)
        }
        if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
            setIsVisibleDatePicker(false)
        }
    }

    useEffect(() => {
        const onClickOutside = (event) => handleClickOutside(event)
        if (isVisible || isVisibleDatePicker) {
            document.addEventListener("click", onClickOutside)
        }
        return () => document.removeEventListener("click", onClickOutside)
    }, [isVisible, isVisibleDatePicker])


    let raw = collection.map((b, index) => <RawOfTableSoundTrap key={index} {...b} visibleColumns={visibleColumns} />)
    const boxInfo = collection?.map((b, index) => <BoxInfo {...b} key={index} />)

    const selectMusic = (e) => {
        window.scrollTo({top: 0})
        dispatch(uploadSoundTrapsFiles(e.target.files))
        dispatch(
            uploadSoundTrapsFiles({
                sounds: e.target.files,
                onProgress: (progressValue) => {
                    setProgress(progressValue)
                },
            })
        ).catch((error) => {
            console.error("Upload failed:", error);
        })
    }

    const startRecognitionProcess = () => {
        window.scrollTo({top: 0})
        dispatch(recognizeSoundTrapsFiles())
    }
    const getCsvFile = () => {
        dispatch(getCsvLink())
    }

    useEffect(() => {
        if (link) {
            const downloadLink = document.createElement('a')
            downloadLink.href = link
            downloadLink.download = 'file.csv'
            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
        }
    }, [link])
    useEffect(() => {
        const itemsInProcess = collection.filter(e => e.status === 'in process')
        if (itemsInProcess.length > 0) {
            dispatch(startPollingProgress())
        }
    }, [collection, dispatch])
    useEffect(() => {
        dispatch(fetchCollection('https://ml1.bird-trap-db.ssrlab.by/api/birds?page_size=10'))
    }, [dispatch])
    if (status === 'loading') {
        return <Loader />
    } 
    return (
        <div className={s.wrapper}>
            {progress && progress >= 0 ? <ProgressBar progress={progress} isUpload={true} t={t}/> : null }
            {recognitionProgress ? <ProgressBar progress={recognitionProgress} isUpload={false} t={t}/> : null }

            <div>
                <div className={s.tablePage__container}>
                    {error ? <h1>An error occured: {error}</h1> :
                        <>
                            <div className={s.table__main}>
                                <div className={s.tittle}>
                                    {t('soundTraps')}
                                </div>
                                <div className={s.buttons_box}>
                                    <div className={s.upload__buttons}>
                                        <label className={s.file_upload} htmlFor="uploadFile">{t('uploadFile')}</label>
                                        <input id="uploadFile" type="file" name="userfile[]" multiple onChange={(e) => selectMusic(e)} />

                                        <div className={s.post}>
                                            <button onClick={() => startRecognitionProcess()}
                                            >{t('startRecognition')}</button>
                                        </div>
                                    </div>
                                    <div className={s.filter_buttons}>
                                        <div className={s.show_but}>
                                            <div onClick={toggleContainer} className={s.filter_but}>{t('ShowColumns')}</div>
                                            {isVisible 
                                                && <CheckboxContainer 
                                                        containerRef={containerRef} 
                                                        setVisibleColumns={setVisibleColumns} 
                                                        visibleColumns={visibleColumns} />
                                            }
                                        </div>
                                        <div className={s.show_but}>
                                            <div onClick={toggleDatePicker} className={s.filter_but}>{t('Filter')}</div>
                                            {isVisibleDatePicker &&
                                                <DateRangeWithTimePicker
                                                    setIsVisibleDatePicker={setIsVisibleDatePicker}
                                                    datePickerRef={datePickerRef}
                                                    dateRange={dateRange}
                                                    setDateRange={setDateRange}
                                                    startTime={startTime}
                                                    setStartTime={setStartTime}
                                                    endTime={endTime}
                                                    setEndTime={setEndTime}
                                                    formatDate={formatDate}
                                                    setPaginatorUrl={setPaginatorUrl}
                                                    />
                                            }
                                        </div>
                                        <div onClick={getCsvFile} className={s.filter_but}>
                                            {t('SaveAsCSV')}
                                        </div>
                                    </div>
                                </div>
                                <div className={s.table__content}>
                                    <table className={s.table}>
                                        <thead>
                                            <tr>
                                                <td>{t('ID')}</td>
                                                {visibleColumns.date && <td>{t('Date')}</td>}
                                                <td>{t('RecordTime')}</td>
                                                {visibleColumns.GPS && <td>{t('GPS')}</td>}
                                                {visibleColumns.temperature && <td>{t('Temperature')}</td>}
                                                {visibleColumns.trapName && <td>{t('TrapName')}</td>}
                                                <td>{t('BirdsRecognised')}</td>
                                                <td>{t('SessionTime')}</td>
                                                <td>{t('Status')}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {raw}
                                        </tbody>
                                    </table>
                                    <div className={s.boxInfo}>
                                        {boxInfo}
                                    </div>
                                </div>
                                <Paginator
                                    url={paginatorUrl} />
                            </div>
                        </>
                    }
                </div>
                <div className={s.upperCloud}></div>
                <div className={s.uuderCloud}></div>
            </div>
        </div>

    )
}