import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { setAuthStatus } from "./authSlice"
import { fetchCollection } from "./collectionSlice"

export const uploadSoundTrapsFiles = createAsyncThunk(
  'soundTraps/uploadSoundTrapsFiles',
  async ({ sounds, onProgress }, { rejectWithValue, dispatch }) => {
    const formData = new FormData()
    for (const sound of sounds) {
      formData.append('files', sound)
    }

    try {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open("POST", "https://ml1.bird-trap-db.ssrlab.by/api/uploadfiles", true)
        xhr.setRequestHeader("Authorization", `Bearer ${localStorage.getItem('token')}`)
        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const progress = Math.round((event.loaded / event.total) * 100)
            onProgress(progress)            
          }
        }
        xhr.onload = () => {
          if (xhr.status === 200) {
            const data = JSON.parse(xhr.responseText)
            onProgress(null)
            dispatch(fetchCollection('https://ml1.bird-trap-db.ssrlab.by/api/birds?page_size=10'))
            
            resolve(data)
          } else {
            if (xhr.status === 401) {
              dispatch(setAuthStatus(false))
            }
            reject(new Error(xhr.statusText))
          }
        }

        xhr.onerror = () => reject(new Error("Network Error"))
        xhr.send(formData)
      })
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const recognizeSoundTrapsFiles = createAsyncThunk(
  'soundTraps/recognizeSoundTrapsFiles',
  async (_, { rejectWithValue, dispatch }) => {
    const body = {
      scheme: 'Bearer',
      credentials: `${localStorage.getItem('token')}`,
    }
    try {
      const response = await fetch('https://ml1.bird-trap-db.ssrlab.by/api/runrecognition', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(setAuthStatus(false))
        }
        throw new Error(response.statusText)
      }
      const data = await response.json()
      dispatch(startPollingProgress())
      return data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const startPollingProgress = createAsyncThunk(
  'soundTraps/startPollingProgress',
  async (_, { dispatch }) => {
    const pollInterval = setInterval(async () => {
      try {
        const response = await fetch('https://ml1.bird-trap-db.ssrlab.by/api/getprogress', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        if (!response.ok) {
          throw new Error('Failed to fetch progress')
        }
        const progressData = await response.json()
        let progress = 0
        if (progressData.startsWith('processing')) {
          const match = progressData.match(/(\d+)%/)
          progress = match ? parseInt(match[1], 10) : 0
          if (progress === 100) {
            progress = 0
          }
        } else if (progressData.startsWith('process finished')) {
          progress = 100
        }
        dispatch(setRecognitionProgress(progress))
        if (progressData.startsWith('process finished')) {
          clearInterval(pollInterval)
          dispatch(fetchCollection('https://ml1.bird-trap-db.ssrlab.by/api/birds?page_size=10'))
          dispatch(setRecognitionProgress(null))
        }
      } catch (error) {
        console.error('Error while fetching recognition progress:', error)
        clearInterval(pollInterval)
      }
    }, 1000)
  }
)

export const getCsvLink = createAsyncThunk(
  'soundTraps/getCsvLink',
  async function (_, { rejectWithValue, dispatch }) {
     
    try {
      const response = await fetch(`https://ml1.bird-trap-db.ssrlab.by/api/birds-in-csv`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      if (!response.ok) {
        if (response.status === 401) {
          
          dispatch(setAuthStatus(false))
        }
        const errorMessage = await response.json()
        throw new Error(errorMessage.detail)
      }
      const data = await response.json()
      return data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

const recognizeSlice = createSlice({
  name: 'recognize',
  initialState: {
    error: null,
    status: null,
    link: null,
    recognitionProgress: null,

  },
  reducers: {
    resetStatus: (state) => {
      state.status = null
    },
    changeLanguages: (state, action) => {
      state.language = action.payload
    },
    setRecognitionProgress(state, action) {
      state.recognitionProgress = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadSoundTrapsFiles.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(uploadSoundTrapsFiles.fulfilled, (state, action) => {
        state.status = 'succeeded'

      })
      .addCase(uploadSoundTrapsFiles.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })
      .addCase(getCsvLink.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(getCsvLink.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.link = action.payload
      })
      .addCase(getCsvLink.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })      
  },
})

export const { resetStatus, changeLanguages, setRecognitionProgress } = recognizeSlice.actions
export default recognizeSlice.reducer

