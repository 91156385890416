import React from "react";
import s from '../../TablePage.module.scss'
import { ReactComponent as Dots } from '../../../../assets/dots.svg'
import { useTranslation } from "react-i18next";

export const RawOfTableSoundTrap = ({
    id,
    date,
    record_time,
    gpslongitude,
    gpslatitude,
    temperature,
    trap_name,
    birdsrecognized,
    status,
    audio_file,
    spectrogram,
    annotation,
    visibleColumns,
    session_time
}) => {
    const showStatus = (status) => {
        switch (status) {
            case 'unrecognized':
                return <div className={s.false_status}>false</div>
            case 'recognized':
                return <div className={s.true_status}>true</div>
            case 'in process':
                return <div className={s.process_status}>in process</div>
            default:
                return <div className={s.false_status}>false</div>
        }
    }
    return (
        <tr className={s.raw_of_table}>
            <td className={s.raw_id}>{id}</td>
            {visibleColumns.date && <td style={{ maxWidth: '100px' }}>{date}</td>}
            <td>{record_time}</td>
            {visibleColumns.GPS && <td>{gpslongitude ?? '- '}, {gpslatitude ?? '-'}</td>}
            {visibleColumns.temperature && <td>{temperature}</td>}
            {visibleColumns.trapName && <td>{trap_name}</td>}
            <td className={s.birds_recognized}>
                <div className={s.birds_recognized_main}>{birdsrecognized?.[0]}</div>
                {birdsrecognized?.length > 1 && <div className={s.birds_recognized_hidden}>{birdsrecognized?.join(', ')}</div>}
            </td>
            <td>{session_time}</td>
            <td className={s.status_raw}>
                {showStatus(status)}
                <div className={s.dots_column_container}>
                    <Dots className={s.dots}/>
                    <ColumnsBox                        
                        audio={audio_file} 
                        spectrogram={spectrogram} 
                        annotation={annotation}/>
                </div>
                
            </td>

        </tr>
    )
}

const ColumnsBox = ({audio, spectrogram, annotation}) => {
    const { t } = useTranslation()
    return (
        <div className={s.column_box}>
            {audio && <a target="blank" href={audio}>
                {t('audiofile')}
            </a>}
            {spectrogram && <a target="blank" href={spectrogram}>
                {t('spectrogram')}
            </a>}
            {annotation && <a target="blank" href={annotation}>
                {t('annotation')}
            </a>}
        </div>
    )
}