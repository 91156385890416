import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import TimePicker from 'react-time-picker'
import 'react-time-picker/dist/TimePicker.css'
import 'react-clock/dist/Clock.css'
import './DateRangePicker.scss'
import { ru, enUS, be } from "date-fns/locale"
import { useDispatch, useSelector } from 'react-redux'
import { getSortedTraps } from '../../store/collectionSlice'
import { useTranslation } from 'react-i18next'

export const DateRangeWithTimePicker = ({
    dateRange,
    setDateRange,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    formatDate,
    datePickerRef,
    setIsVisibleDatePicker,
    setPaginatorUrl
}) => {
    const { language } = useSelector(state => state.recognize)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const handleRangeChange = (ranges) => {
        setDateRange([ranges.selection])
    }

    const getLocale = () => {
        switch (language) {
            case 1:
                return ru
            case 2:
                return be
            case 3:
                return enUS
            default:
                break
        }
    }

    const applyDatePicker = () => {
        setPaginatorUrl(`https://ml1.bird-trap-db.ssrlab.by/api/birds/paginated/sorted-filter?date_start=${formatDate(dateRange[0].startDate)}&date_finish=${formatDate(dateRange[0].endDate)}&time_start=${startTime}:00&time_finish=${endTime}:00&page_size=10&page_num=`)
        dispatch(getSortedTraps({
            startDate: formatDate(dateRange[0].startDate),
            finishDate: formatDate(dateRange[0].endDate),
            startTime,
            finishTime: endTime
        }))
        setIsVisibleDatePicker(false)
    }

    const isApplyDisabled = () => {
        const isValidTime = (time) => {
            if (!time) return false
            const [hours, minutes] = time.split(':')
            return !isNaN(hours) && !isNaN(minutes) && +hours >= 0 && +hours < 24 && +minutes >= 0 && +minutes < 60
        }

        return (
            !dateRange[0]?.startDate ||
            !dateRange[0]?.endDate ||
            !isValidTime(startTime) ||
            !isValidTime(endTime)
        )
    }

    const resetFilter = () => {
        setDateRange([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            },
        ])
        setStartTime('00:00')
        setEndTime('23:59')
    }

    return (
        <div ref={datePickerRef} onClick={(e => e.stopPropagation())} className='date_range_container'>
            <DateRangePicker
                ranges={dateRange}
                onChange={handleRangeChange}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                direction="vertical"
                locale={getLocale()}
            />

            <div className='select_info'>
                <div>{t('from')}</div>
                <div className='selected_date'>
                    <span>{formatDate(dateRange[0].startDate)}</span>

                    <TimePicker
                        className={'time_picker'}
                        onChange={setStartTime}
                        value={startTime}
                        disableClock={true}
                        format="HH:mm"
                    />
                </div>
            </div>

            <div>
                <div>{t('to')}</div>
                <div className='selected_date'>
                    <span>
                        {formatDate(dateRange[0].endDate)}
                    </span>
                    <TimePicker
                        className={'time_picker'}
                        onChange={setEndTime}
                        value={endTime}
                        disableClock={true}
                        format="HH:mm"
                    />
                </div>
            </div>
            <div>
                <button disabled={isApplyDisabled()} className='apply_button' onClick={applyDatePicker}>{t('apply')}</button>
            </div>
            <div>
                <button className='reset_button' onClick={() => resetFilter()}>{t('resetFilter')}</button>
            </div>
        </div>
    )
}

